import { USER_LIST_PAGINATION } from '../constants';

export const initialStore = {
	form: {},
	global: {
		collapsed: false,
	},
	signInPage: {
		isFetching: false,
		error: null,
	},
	passwordReset: {
		isFetching: true,
		error: null,
	},
	passwordRestore: {
		isFetching: true,
		error: null,
		token: null,
		success: false,
	},
	users: {
		isAuthorized: false,
		isFetching: false,
		currentUser: {
			id: '',
			name: '',
			picture: '',
			email: '',
			createdAt: '',
			role: '',
			language: '',
		},
		initialValue: {
			name: '',
			picture: '',
			email: '',
			role: '',
			language: '',
			id: '',
		},
		searchQueryString: '',
		detectedEmail: '',
		listOfUsers: [],
		count: 0,
		paginationProperty: USER_LIST_PAGINATION,
	},
	members: {
		currentMember: {
			createdAt: '',
			id: '',
			name: '',
			position: '',
			image: '',
			socialLink: '',
			order: '',
			email: '',
			updatedAt: '',
		},
		searchQueryString: '',
		isFetching: false,
		listOfMembers: [],
		count: 0,
	},
	sales: {
		isFetching: false,
		advertsList: [],
		count: 0,
	},
	portfolio: {
		isFetching: false,
		error: null,
		portfolioItems: [],
		searchQueryString: '',
		count: 0,
		success: false,
		currentPortfolio: {
			id: '',
			title: '',
			description: '',
			link: '',
			image: '',
			order: '',
			type: '',
			modalImages: [],
		},
	},
	services: {
		servicesList: [],
		count: 0,
		success: false,
		currentServices: {
			id: '',
			entityMap: {},
			blocks: [],
			position: '',
		},
	},
};
