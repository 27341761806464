import React, { Component } from 'react';
import { http } from '../../axios';
import axios from 'axios';

// MODELS
import { I18n } from '../../models/i18n';

// COMPONENTS
import { Upload } from 'antd';
import { CustomIcon } from '../../components';
import { openNotificationFail } from '../../utils/show-notification';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';

function beforeUpload(file: File) {
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		openNotificationFail({ message: 'Image must be smaller than 2MB!' });
	}
	return isLt2M;
}

interface UploadProps {
	getPictureUrl: (value: string) => void;
	defaultUrl: string;
	domainUrl: string;
	uploadUrl: string;
	className?: string;
	update: boolean;
	USERS_PAGE: I18n['USERS_PAGE'];
}

type UploadState = {
	loading: boolean;
	imageUrl: string;
};

export class CustomUpload extends Component<UploadProps, UploadState> {
	constructor(props: UploadProps) {
		super(props);

		this.state = {
			loading: false,
			imageUrl: '',
		};
	}

	avatarRef: React.RefObject<HTMLImageElement> = React.createRef();

	handleChange = async (info: any) => {
		if (info.file.status === 'uploading') {
			this.setState({ loading: true });
			return;
		}
		if (info.file.status === 'done') {
			this.setState({ loading: false });
			return;
		}
	};

	handleUplaod = async (options: RcCustomRequestOptions) => {
    const { getPictureUrl, uploadUrl, domainUrl, defaultUrl, update } = this.props;
		const { file } = options;

		let fileKey = '';

		if (update) {
			const domainRegExp = new RegExp(`${domainUrl.replace(/\//gi, '\\/')}`, 'gi');
			fileKey = defaultUrl.replace(domainRegExp, '').split('?')[0];
		}

		const uploadConfig = await http({
			url: uploadUrl,
			method: 'POST',
			data: { fileKey },
		});
		const uploadingUrl = uploadConfig.data.url;
		const fileName = uploadConfig.data.key;

	await axios.put(uploadingUrl, file, {
			headers: {
				'Content-Type': file.type,
			},
    });

    this.setState({ imageUrl: `${domainUrl}${fileName}` });
    const { imageUrl } = this.state;
    if (this.avatarRef.current) this.avatarRef.current.src = imageUrl;
    getPictureUrl(imageUrl);
	};

	render() {
		const { defaultUrl, USERS_PAGE } = this.props;
		const { loading, imageUrl } = this.state;

		const avatarUrl = imageUrl || defaultUrl;
		const uploadButton = (
			<div>
				{loading ? <CustomIcon type="loading" /> : <CustomIcon type="plus" />}
				<div style={{ marginTop: 8 }}>{USERS_PAGE.uploadImage}</div>
			</div>
		);
		return (
			<Upload
				name="avatar"
				listType="picture-card"
				className="avatar-uploader"
				showUploadList={false}
				accept="image/*"
				method="put"
				customRequest={this.handleUplaod}
				beforeUpload={beforeUpload}
				onChange={this.handleChange}
			>
				{avatarUrl ? <img ref={this.avatarRef} src={avatarUrl} alt="Avatar" style={{ width: '100%' }} /> : uploadButton}
			</Upload>
		);
	}
}
