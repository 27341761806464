import { CustomAvatar } from './avatar';
import { CustomButton } from './button';
import { CustomIcon } from './icons';
import { CustomModal } from './modal';
import { Spinner } from './spinner';
import { CustomTable } from './table';
import { Logo } from './logo';
import { CustomCard } from './card';
import CustomLayout from './layout';
import { CustomHeader } from './header';
import { CustomTag } from './tag';
import { CustomPopconfirm } from './pop-confirm';
import { CustomUpload } from './upload';

export {
	CustomCard,
	CustomAvatar,
	CustomButton,
	CustomIcon,
	CustomModal,
	Spinner,
	CustomTable,
	Logo,
	CustomLayout,
	CustomHeader,
	CustomTag,
	CustomPopconfirm,
	CustomUpload,
};
